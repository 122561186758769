












































































































































































































import useAccessory from "@/use/accessory";
import useApi from "@/use/api";
import useAttachment from "@/use/attachment";
import useUser from "@/use/user";
import {
  computed,
  defineComponent,
  nextTick,
  reactive,
  watch,
} from "@vue/composition-api";

export default defineComponent({
  components: {
    OProtocolModal: () => import("@/components/organisms/o-protocol-modal.vue"),
    MInventoryVehiclesSelect: () =>
      import("@/components/molecules/m-inventory-vehicles-select.vue"),
    MInventoryItemsSelect: () =>
      import("@/components/molecules/m-inventory-items-select.vue"),
    MInventoryUsersSelect: () =>
      import("@/components/molecules/m-inventory-users-select.vue"),
    MDatePicker: () => import("@/components/molecules/m-date-picker.vue"),
  },
  props: {
    isOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
    defaults: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  setup(props, { root, emit }) {
    const { hasAccessTo } = useUser({ root });

    const { getAccessoryIcon } = useAccessory();
    const { uploadAttachments } = useAttachment({ root });

    const state = reactive({
      loading: false,
      company: null,
      step: 1,
    });

    const model = reactive({
      type: "",
      user: null as any,
      vehicle: null as any,
      accessory: null as any,
      attachments: [] as any[],
      protocols: [] as any[],
      date: root.$moment(new Date(Date.now())).format("YYYY-MM-DD"),
    });

    watch(
      () => model.user,
      (newUser, oldUser) => {
        if (oldUser && newUser?.id !== oldUser?.id) {
          model.vehicle = null;
          model.accessory = null;
        }
      }
    );

    watch(
      () => model.type,
      (newType, oldType) => {
        if (oldType && newType !== oldType) {
          model.vehicle = null;
          model.accessory = null;
        }
      }
    );

    const types = [
      { value: "vehicleHandover", text: "Wydanie pojazdu" },
      { value: "vehicleReturn", text: "Zdanie pojazdu" },
      { value: "accessoryHandover", text: "Wydanie akcesorium" },
      { value: "accessoryReturn", text: "Zdanie akcesorium" },
    ];

    const typeLabel = computed(() => {
      const type = types.find((type) => type.value === model.type);
      if (model.type && type) return type.text;
      else return null;
    });

    const userLabel = computed(() => {
      const user = model.user as any;
      if (user) {
        if (user.firstName || user.lastName)
          return `${user.firstName} ${user.lastName}`;
        else return user;
      }

      return null;
    });

    const itemLabel = computed(() => {
      if (["vehicleHandover", "vehicleReturn"].includes(model.type)) {
        const vehicle = model.vehicle as any;
        if (vehicle) {
          if (vehicle.details && vehicle.details.registrationNumber)
            return `${vehicle.details.vehicleBrand} ${vehicle.details.vehicleModel} - ${vehicle.details.registrationNumber}`;
          else return vehicle;
        }
      } else if (
        ["accessoryHandover", "accessoryReturn"].includes(model.type)
      ) {
        const accessory = model.accessory as any;
        if (accessory) {
          if (accessory.name) return accessory.name;
          else return accessory;
        }
      }

      return null;
    });

    const attachmentsLabel = computed(() => {
      const date = model.date;
      const attachmentsCount = model.attachments?.length ?? 0;

      const result = [];

      if (date) result.push(`Data: ${root.$moment(date).format("DD.MM.YYYY")}`);
      if (attachmentsCount) result.push(`Liczba plików: ${attachmentsCount}`);

      return result.join(", ");
    });

    const isStepValid = (step?: number) => {
      switch (step) {
        case 4:
          return !!model.date;
        case 3:
          return ["vehicleHandover", "vehicleReturn"].includes(model.type)
            ? !!model.vehicle
            : !!model.accessory;
        case 2:
          return !!model.user;
        default:
        case 1:
          return !!model.type;
      }
    };

    const isValid = () =>
      isStepValid(1) && isStepValid(2) && isStepValid(3) && isStepValid(4);

    const prev = () => (state.step -= 1);
    const next = () => (state.step += 1);

    const reset = (hard = false) => {
      if (hard) {
        state.step = 1;
        model.type = "";
      }

      model.user = null;
      model.vehicle = null;
      model.accessory = null;
      model.attachments = [];
      model.protocols = [];
    };

    watch(
      () => state.company,
      () => reset()
    );

    const close = () => {
      emit("update:isOpen", false);
      nextTick(() => reset(true));
    };

    const save = async () => {
      state.loading = true;

      if (isValid()) {
        const { axiosInstance } = useApi({ root });

        const actionData = {
          type: model.type || undefined,
          user: model.user ? model.user.id : undefined,
          vehicle: model.vehicle ? model.vehicle.id : undefined,
          accessory: model.accessory ? model.accessory.id : undefined,
          date: model.date || undefined,
          attachments: undefined,
        };

        await uploadAttachments([...model.attachments, ...model.protocols])
          .then(({ data }) => {
            actionData.attachments = data.attachments.map(
              (attachment: { id: string }) => attachment.id
            );
          })
          .catch(console.log);

        axiosInstance
          .post("inventory", actionData)
          .then(() => {
            emit("refresh");
            nextTick(close);
          })
          .catch(console.log)
          .finally(() => (state.loading = false));
      } else {
        setTimeout(() => (state.loading = false), 1000);
      }
    };

    watch(
      () => props.defaults,
      (options) => {
        if (options) {
          if (options.type) model.type = options.type;
          if (options.user) model.user = options.user;
          if (options.vehicle) model.vehicle = options.vehicle;
          if (options.accessory) model.accessory = options.accessory;
          if (options.step) state.step = options.step;
        }
      }
    );

    const protocolVariables = computed(() => ({
      type: model.type as "vehicleReturn" | "vehicleHandover",
      user: model.user,
      vehicle: model.vehicle,
      company: model.vehicle?.company,
      handoverDate: model.date,
      returnDate: model.date,
    }));

    const addProtocols = (documents: File[]) => {
      model.protocols = [...documents];
    };

    return {
      hasAccessTo,
      getAccessoryIcon,

      state,
      model,
      types,

      typeLabel,
      userLabel,
      itemLabel,
      attachmentsLabel,

      prev,
      next,
      close,
      save,
      isStepValid,
      isValid,

      protocolVariables,
      addProtocols,
    };
  },
});
